exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-author-bppl-2-2-jsx": () => import("./../../../src/pages/author/bppl-2-2.jsx" /* webpackChunkName: "component---src-pages-author-bppl-2-2-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-esg-jsx": () => import("./../../../src/pages/esg.jsx" /* webpackChunkName: "component---src-pages-esg-jsx" */),
  "component---src-pages-faq-page-jsx": () => import("./../../../src/pages/faq-page.jsx" /* webpackChunkName: "component---src-pages-faq-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-jsx": () => import("./../../../src/pages/investor-relations.jsx" /* webpackChunkName: "component---src-pages-investor-relations-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-search-result-jsx": () => import("./../../../src/pages/search-result.jsx" /* webpackChunkName: "component---src-pages-search-result-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-web-template-archives-jsx": () => import("./../../../src/pages/web-template/archives.jsx" /* webpackChunkName: "component---src-pages-web-template-archives-jsx" */),
  "component---src-pages-web-template-catagary-jsx": () => import("./../../../src/pages/web-template/catagary.jsx" /* webpackChunkName: "component---src-pages-web-template-catagary-jsx" */),
  "component---src-pages-web-template-form-jsx": () => import("./../../../src/pages/web-template/form.jsx" /* webpackChunkName: "component---src-pages-web-template-form-jsx" */),
  "component---src-pages-web-template-readmorepage-2-jsx": () => import("./../../../src/pages/web-template/readmorepage2.jsx" /* webpackChunkName: "component---src-pages-web-template-readmorepage-2-jsx" */),
  "component---src-pages-web-template-readmorepage-3-jsx": () => import("./../../../src/pages/web-template/readmorepage3.jsx" /* webpackChunkName: "component---src-pages-web-template-readmorepage-3-jsx" */),
  "component---src-pages-web-template-readmorepage-jsx": () => import("./../../../src/pages/web-template/readmorepage.jsx" /* webpackChunkName: "component---src-pages-web-template-readmorepage-jsx" */)
}

